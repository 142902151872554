import { useViewPort } from '@/composables'
import { DataType, DataViewBreakpoints, ListColumn } from '@/interfaces'
import store from '@/store'
import { computed } from 'vue'
import { MIN_LAYOUT_BREAKPOINTS } from '@/services/constants'

interface Column {
  name: string
  position?: number
  priority?: number
  breakpoints?: string[]
}

type DataViewBreakpointsType = typeof DataViewBreakpoints
type DataViewBreakpointValue = { [key in keyof DataViewBreakpointsType]: number }
type BreakpointKeys = keyof DataViewBreakpointsType

const { getCurrentBreakpoint } = useViewPort()

export const breakpointDataViewType = (): DataViewBreakpointValue => {
  const dataViewTypeMap: Partial<Record<DataType, BreakpointKeys>> = {
    [DataType.ExcludedDomains]: 'ExcludedDomains',
    [DataType.OfferedPackages]: 'OfferedPackages',
    [DataType.Video]: 'Video',
    [DataType.Folder]: 'Folder',
    [DataType.Package]: 'Package',
    [DataType.Playlist]: 'Playlist',
    [DataType.Widget]: 'Widget',
    [DataType.MyDomains]: 'MyDomains',
    [DataType.Users]: 'Users',
    [DataType.ContentSources]: 'ContentSources',
    [DataType.AgencyAccounts]: 'AgencyAccounts',
    [DataType.PairedUrls]: 'PairedUrls'
  }

  const dataType = store.state.dataViewType as DataType
  const viewTypeKey = dataViewTypeMap[dataType]

  if (!viewTypeKey) {
    throw new Error('Invalid or missing data type in DataView component')
  }

  const breakpoints = DataViewBreakpoints[viewTypeKey] as unknown as DataViewBreakpointValue

  return breakpoints
}

const sortByPosition = (columns: ListColumn[]) => columns.sort((a, b) => a.position! - b.position!)
const getVisibleColumns = (columns: ListColumn[]) => {
  return sortByPosition(columns.filter((col) => col && col.position !== undefined))
}

function getGridColsWidth(adjustedColumns: ListColumn[]) {
  const sortedColsByPos = adjustedColumns.sort((a, b) => a.position! - b.position!)
  const gridColsWidth = sortedColsByPos
    .map((item) => {
      if (item.minWidthInPx) {
        return `minmax(${item.minWidthInPx}, ${item.width}%)`
      } else {
        return item.expand ? 'auto' : `${item.width}%`
      }
    })
    .join(' ')
  return gridColsWidth
}

export const columnsGridTemplate = computed(() => {
  return (columns: ListColumn[]) => {
    if (!columns) return

    const breakpoints = breakpointDataViewType()
    const currentBreakpoint = getCurrentBreakpoint() as BreakpointKeys
    const maxColumns = Math.min(breakpoints[currentBreakpoint] ?? columns.length, columns.length)

    const visibleColumns = getVisibleColumns(columns)
    const [priorityZeroColumns, priorityOneColumns] = [visibleColumns.filter((col) => col.priority === 0), visibleColumns.filter((col) => col.priority === 1)]

    const adjustedColumns = [...priorityOneColumns, ...priorityZeroColumns]

    if (!MIN_LAYOUT_BREAKPOINTS.includes(currentBreakpoint)) {
      const remainingColumns = visibleColumns.filter((col) => col.priority > 1)
      for (let priority = 2; priority <= Math.max(...visibleColumns.map((col) => col.priority || 0)); priority++) {
        const priorityCols = remainingColumns.filter((col) => col.priority === priority)
        if (adjustedColumns.length + priorityCols.length <= maxColumns) {
          adjustedColumns.push(...priorityCols)
        }
      }
    }
    return getGridColsWidth(adjustedColumns)
  }
})

export const shouldHideOnBreakpoint = computed(() => {
  return (columns: Column[], columnName: string): Record<string, boolean> => {
    if (!columns) {
      return { hidden: true }
    }

    const breakpoints = breakpointDataViewType()
    const currentBreakpoint = getCurrentBreakpoint() as BreakpointKeys
    let maxColumns = columns.length
    if (breakpoints && breakpoints[currentBreakpoint]) {
      maxColumns = breakpoints[currentBreakpoint]
    }

    const visibleColumns = columns.filter((col) => col && col.position !== undefined).sort((a, b) => (a.position ?? 0) - (b.position ?? 0))

    const priorityZeroColumns = visibleColumns.filter((col) => col.priority === 0)
    const priorityOneColumns = visibleColumns.filter((col) => col.priority === 1)
    const otherColumns = visibleColumns.filter((col) => col.priority !== 0 && col.priority !== 1).sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))

    let adjustedColumns = []
    if (MIN_LAYOUT_BREAKPOINTS.includes(currentBreakpoint)) {
      adjustedColumns = [...priorityOneColumns, ...priorityZeroColumns]
    } else {
      adjustedColumns = [...priorityOneColumns, ...otherColumns, ...priorityZeroColumns]

      for (let priority = 2; priority <= Math.max(...visibleColumns.map((col) => col.priority ?? 0)); priority++) {
        const priorityColumns = visibleColumns.filter((col) => col.priority === priority)
        if (adjustedColumns.length + priorityColumns.length <= maxColumns) {
          adjustedColumns = [...adjustedColumns, ...priorityColumns]
        }
      }
    }

    const finalColumns = adjustedColumns
      .slice(0, maxColumns)
      .concat(priorityZeroColumns)
      .sort((a, b) => (a?.position ?? Number.MAX_SAFE_INTEGER) - (b?.position ?? Number.MAX_SAFE_INTEGER))

    const column = finalColumns.find((col) => col.name === columnName)

    if (column) {
      let hidden = false
      let orderClass = `order-${maxColumns}`

      if (column.name === 'actions') {
        orderClass = column.position !== undefined ? `order-${columns.length}` : ''
      } else {
        orderClass = column.position !== undefined ? `order-${column.position}` : ''
      }

      if (breakpoints[currentBreakpoint] >= columns.length) {
        if (column.position !== undefined && column.position >= maxColumns) {
          hidden = true
        }
      } else if (column.priority !== undefined && column.priority >= maxColumns && column.name !== 'actions') {
        hidden = true
      }

      return {
        hidden,
        [orderClass]: true
      }
    }

    return { hidden: true }
  }
})

export const columnsToDisplay = computed(() => {
  return (columns: Column[]) => {
    const currentBreakpoint = getCurrentBreakpoint() as BreakpointKeys
    return columns.filter((column) => !column?.breakpoints || !column.breakpoints?.includes(currentBreakpoint))
  }
})

export enum BreakpointsWidths {
  XS = 0,
  XM = 364,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1400,
  XXXL = 1600,
  HD = 1900
}
