<template>
  <div
    v-if="props.item"
    ref="videoelement"
    :style="{ borderRadius: `${currentBorderRadius[0]}px ${currentBorderRadius[1]}px ${currentBorderRadius[2]}px ${currentBorderRadius[3]}px` }"
    :class="{
      cursor: props.defaultCursor,
      'video _no-hover': !shouldHoverAnimation,
      'video hoverover hover-disabled': props.bare && !shouldHoverAnimation,
      'video hoverover': shouldHoverAnimation && !props.bare
    }"
    @click.stop="clickAction()"
  >
    <ImageComponent
      :source="props.item.thumbnails && props.item.thumbnails.length ? props.item.thumbnails[0].url : ''"
      :class="{ thumbnail: true, _selected: props.selectOnly && isSelected, invisible: playing }"
      :border-radius="currentBorderRadius"
      @click.stop="clickAction()"
    />

    <LoadingSpinner class="loading" :class="{ visible: !playing && showPlayer }" :size="'small'" />
    <figure v-show="videoUrl.length > 0 && showPlayer" class="video-player">
      <video
        ref="videoplayer" autoplay muted loop
        class="video-player" @click.stop="clickAction()"
      />
      <figcaption :class="{ jump: progressJump }">
        <progress
          id="progress" ref="progressbar" class="progress" max="100"
          value="0"
        >
          Progress
        </progress>
      </figcaption>
    </figure>
    <div v-if="!props.removeLabels" class="labels" :class="{ bare: bare || props.removeAddButton }">
      <VideoComponentCategories
        v-if="props.item.categories" :categories="props.item.categories" class="categories" :class="cSize.breakpointsAdditive"
        is-on-video-thumb
      />
      <div>
        <div v-if="props.item && props.item.ownership && !isPageRouteMyVideosOrLibrary" class="label" :class="cSize.breakpointsAdditive">{{ $t('own') }}</div>
      </div>
    </div>

    <div v-if="threeDots" class="add-to-playlist-btn">
      <DropdownComponent v-if="threeDots" @click.stop>
        <template #trigger>
          <img src="@/assets/playlists/playlist/menu.svg">
        </template>
        <template #content="scopeProps">
          <div
            class="dropdown-item"
            @click.stop="
              () => {
                scopeProps.close()
                clickAction()
              }
            "
          >
            <IconWrapper class="dropdown-item-icon" :name="'editIcon'" />
            <span>{{ $t('content-video-edit') }}</span>
          </div>
          <div
            v-if="checkPermission('widgets')"
            class="dropdown-item"
            @click.stop="
              () => {
                scopeProps.close()
                detailsEmbed()
              }
            "
          >
            <IconWrapper class="dropdown-item-icon" :name="'embedIcon'" />
            <span>{{ $t('content-video-embed') }}</span>
          </div>
          <div
            class="dropdown-item"
            @click.stop="
              () => {
                scopeProps.close()
                openDeleteVideo()
              }
            "
          >
            <IconWrapper class="dropdown-item-icon" :name="'deleteIcon'" />
            <span>{{ $t('content-video-delete') }}</span>
          </div>
        </template>
      </DropdownComponent>
    </div>
    <div v-else-if="!props.selectOnly && !creatingPlaylist" class="add-to-playlist-btn">
      <VideoActionButton
        v-if="!props.removeEmbedButton && !props.removeAddButton && !creatingPlaylist"
        :text="$t('embed-video')"
        :icon="IconEmbed"
        :class-list="'darkgray'"
        :select-only="false"
        @click="detailsEmbed"
      />
      <VideoActionButton
        v-if="!props.selected && !isSelected && !props.removeAddButton"
        :text="$t('add-to-playlist')"
        :icon="IconAdd"
        :class-list="'red'"
        :select-only="false"
        @click="onPlaylistAction"
      />
    </div>
    <div v-else-if="props.selectOnly || (creatingPlaylist && isCreatingPlaylistOnAddVideoState)" class="add-to-playlist-btn">
      <VideoActionButton
        v-if="!props.removeAddButton"
        :text="isSelected ? $t('remove-from-playlist') : $t('add-to-playlist')"
        :icon="isSelected ? IconRemove : IconAdd"
        :class-list="'red'"
        :select-only="true"
        @click="onPlaylistAction"
      />
    </div>
    <div v-if="showTime" class="video-duration-container" :class="cSize.breakpointsAdditive">
      {{ playingtime }}
    </div>
    <div v-if="props.showTopText" class="video-info-container">
      <div class="title top" :class="cSize.breakpointsAdditive">
        <span class="title-text">
          {{ props.item.title }}
        </span>
      </div>
      <div class="author top" :class="cSize.breakpointsAdditive">
        <div class="author-container">
          <span v-if="props.item.ownership">{{ t('own') }}</span>
          <span v-else class="author-by">
            {{ $t('library-video-by') }}
            <span class="author-name">{{ shortenText(props.item.organization_name, 18) }}</span>
          </span>
          <span class="bullet-separator">●</span>
          <span class="duration">
            {{ getDateFormat(props.item.upload_date) }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="props.withBottom" class="bottom-extra-video-info">
      <div class="video-extra-details">
        <LoadingSpinner v-if="isHandleLoading" class="loading" :class="{ visible: props.isHandleLoading }" :size="'small'" />
        <ProfileImage :size="1" :custom-width-height="'32px'" @click.stop="handleOrganizationNameClicked" />
        <div class="video-extra-details-title-author-container">
          <div class="title-text" :style="!withTitleEvents ? 'pointer-events: none' : ''">
            <Tooltip placement="top" class="tooltip-title-text" :content="props.item.title" auto-ellipsis />
          </div>
          <div class="author">
            <div class="author-container">
              <span v-if="props.item.ownership">{{ t('own') }}</span>
              <div v-else-if="props.item.organization_name" class="author-by" @click.stop="handleOrganizationNameClicked">
                {{ $t('library-video-by') }}
                <span>&nbsp;</span>
                <Tooltip
                  placement="top" class="tooltip-title-text" :class="{ hoverable: props.isAuthorByHoverable }" :content="props.item.organization_name"
                  :text-length="17"
                />
              </div>
              <span v-if="props.item.organization_name" class="bullet-separator">●</span>
              <span class="duration">
                {{ getDateFormat(props.item.upload_date) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="interactive && !creatingPlaylist" class="details" @click="onClick ? onClick() : details()">
          <img src="@/assets/library/video/iconVideoOpenDetails.svg">
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ImageComponent, DropdownComponent, LoadingSpinner, ProfileImage, Tooltip } from '@/components/common'
import { shortenText, formatDuration, formatDateWithMonthName, openModalCustomConfirmation, getLibraryChannelHandle } from '@/services/utils'
import { MILLISECONDS_PER_SECOND, TRUVID_CREATE_PLAYLIST_STATES } from '@/services/constants'
import IconWrapper from '@/components/common/icons/IconWrapper.vue'
//images
import IconAdd from '@/assets/common/add-icon.svg'
import IconRemove from '@/assets/common/remove-icon.svg'
import IconEmbed from '@/assets/common/embed-icon.svg'
import VideoActionButton from './VideoActionButton.vue'
import VideoComponentCategories from './videoComponent/VideoComponentCategories.vue'
import { uid } from 'uid'
import { onMounted, onUnmounted } from 'vue'
import Hls from 'hls.js'
import { checkPermission } from '@/store/permissions'
import { useStore } from 'vuex'
import type { Video } from '@/services/videos/types'
import { componentSize } from '@/composables'
import { BaseRoutes, CRUD, DataActions, DataType } from '@/interfaces'
import { useI18n } from 'vue-i18n'
import { VIDEO_ACTIONS_INJECTION_KEY } from '@/constants'
import { apiService } from '@/services'
import { ModalType } from '@/components/modals/modals-util'

interface videoProps {
  item: Video
  selected?: boolean
  bare?: boolean
  removeEmbedButton?: boolean
  removeAddButton?: boolean
  withBottom?: boolean
  imageIdx?: number
  threeDots?: boolean
  withTitleEvents?: boolean
  hoverAnimation?: boolean
  selectOnly?: boolean
  interactive?: boolean
  customLink?: string
  onClick?: () => void
  detailsLink?: string
  defaultCursor?: boolean
  playVideo?: boolean
  showTime?: boolean
  showOwnership?: boolean
  showTopText?: boolean
  pageRoute?: BaseRoutes
  removeLabels?: boolean
  borderRadius?: number[]
  isAuthorByHoverable?: boolean
  isHandleLoading?: boolean
}

const props = withDefaults(defineProps<videoProps>(), {
  customLink: null,
  imageIdx: 1,
  withBottom: true,
  threeDots: false,
  withTitleEvents: true,
  selectOnly: false,
  hoverAnimation: true,
  interactive: true,
  defaultCursor: true,
  playVideo: true,
  showTime: true,
  showOwnership: true,
  showTopText: true,
  removeLabels: false,
  isAuthorByHoverable: false
})

const router = useRouter()
const store = useStore()
const emit = defineEmits(['selectvideo', 'close', 'video:clicked', 'on-author'])
const progressbar = ref(null)
const videoplayer = ref<InstanceType<typeof HTMLVideoElement>>(null)
const videoInterval = ref()
const playingtime = ref('0')
const shouldHoverAnimation = ref()
const isCreatingPlaylistOnAddVideoState = computed(() => store.getters['playlists/getCreatePlaylistStep']() === TRUVID_CREATE_PLAYLIST_STATES.ADD_VIDEOS)
const videoelement = ref<HTMLVideoElement | null>(null)
const showPlayer = ref(false)
const progressJump = ref(false)
const startPlaybackGuardId = ref()
const currentBorderRadius = ref([6, 0, 0, 6])
const playerOptions = {
  startLevel: 1,
  maxBufferLength: 8,
  maxMaxBufferLength: 8,
  autoPlay: true,
  testBandwidth: false,
  capLevelToPlayerSize: true,
  debug: false
}
// const inFolderView = computed(() => router.currentRoute.value.name !== 'videos' && router.currentRoute.value.name !== 'edit-package')
const videoUrl = ref('')
const cSize = reactive(componentSize())
const isSelected = computed(() => {
  if (router.currentRoute.value.path.includes('/packages/')) {
    return !!store.state.packages?.selectedVideos?.find((v) => v.video_id === props.item.video_id)
  } else {
    return !!store.state.playlists?.selectedVideos?.find((v) => v.video_id === props.item.video_id)
  }
})
const libraryHandle = getLibraryChannelHandle()

async function getVideoUrl() {
  const format = props.item.formats.find((format) => {
    return format.format === 'hls'
  })

  videoUrl.value = format?.path_in_cdn ? format.path_in_cdn : ''
}

function getVideoTime() {
  if (playing.value && videoplayer.value) {
    playingtime.value = formatDuration(videoplayer.value.currentTime * 1000) // convert seconds to miliseconds
  } else {
    playingtime.value = formatDuration(props.item.duration_in_ms)
  }
}

function getDateFormat(date: string) {
  const d = new Date(date)
  return formatDateWithMonthName(d)
}

const creatingPlaylist = computed(() => {
  return router.currentRoute.value.path.includes('/createplaylist')
})
const isPageRouteMyVideosOrLibrary = computed(() => {
  return props.pageRoute === BaseRoutes.videos || props.pageRoute === BaseRoutes.library
})
const { t } = useI18n()

const modalType = ModalType.VIDEOS_FOLDER_DELETE_VIDEO

const dataActions = inject<DataActions | null>(VIDEO_ACTIONS_INJECTION_KEY, null)

const deleteVideo = async (params) => {
  return apiService.videos
    .deleteVideo(params.id as string)
    .then(() => {
      if (dataActions?.refreshData) dataActions.refreshData()
      if (dataActions?.clearSearch) dataActions.clearSearch()
      store.dispatch('messages/addMessage', {
        message: t('video-deleted'),
        type: 'success'
      })
    })
    .catch((err) => {
      store.dispatch('messages/addMessage', {
        message: err,
        type: 'fail'
      })
    })
}

onMounted(() => {
  shouldHoverAnimation.value = props.hoverAnimation
  getVideoUrl()
  getVideoTime()
  if (props.playVideo && videoUrl.value.length > 0) {
    videoelement.value.addEventListener('mouseleave', onMouseOut)
    videoelement.value.addEventListener('mouseenter', onMouseEnter)
  }
  cSize.addObserver(videoelement.value)
  currentBorderRadius.value = props.borderRadius ? props.borderRadius : [6, 6, 6, 6]
})

onUnmounted(() => {
  if (videoelement.value) {
    videoelement.value.removeEventListener('mouseleave', () => {
      onMouseOut()
    })
    videoelement.value.removeEventListener('mouseenter', () => {
      onMouseEnter()
    })
    clearInterval(videoInterval.value)
  }
  getVideoTime()
})

function onPlaylistAction(): void {
  const clone = JSON.parse(JSON.stringify(props.item))
  if (router.currentRoute.value.path.includes('/packages/')) {
    if (isSelected.value) {
      store.commit('packages/deselectVideo', props.item.video_id)
      return
    }
    const filtered = store.state.packages.selectedVideos.filter((video) => video.video_id === clone.video_id)
    if (!filtered.length) {
      clone.uid = uid()
      store.commit('packages/selectVideo', clone)
    }
  } else {
    if (isSelected.value) {
      store.commit('playlists/deselectVideo', props.item.video_id)
      return
    }
    const filtered = store.state.playlists.selectedVideos.filter((video) => video.video_id === clone.video_id)
    const createPlaylistStep = store.getters['playlists/getCreatePlaylistStep']()
    if (!filtered.length && createPlaylistStep === TRUVID_CREATE_PLAYLIST_STATES.ADD_VIDEOS) {
      clone.uid = uid()
      store.commit('playlists/selectVideo', clone)
      const currPath = router.currentRoute.value.path
      if (!currPath.includes('createplaylist')) {
        router.push({ name: libraryHandle ? 'createplaylist-library-channel' : 'createplaylist' })
      }
    }
  }
}

function details(): void {
  if (props.customLink && props.customLink.length > 0) {
    router.push(props.customLink)
  } else if (router.currentRoute.value.path.includes(props.detailsLink)) {
    emit('close', { id: props.item.video_id, organization_id: props.item.organization_id })
  } else if (props.item.video_id) {
    router.push({ name: props.detailsLink, params: { id: props.item.video_id } })
  }
}

function detailsEmbed(): void {
  if (router.currentRoute.value.path.includes('library') || router.currentRoute.value.path.includes('dashboard')) {
    if (libraryHandle) {
      router.push({ name: 'library-channel-details', params: { id: props.item.video_id }, query: { embed: 'true' } })
    } else router.push({ name: 'library-details', params: { id: props.item.video_id }, query: { embed: 'true' } })
  } else {
    router.push({ name: 'videos-details', params: { id: props.item.video_id }, query: { embed: 'true' } })
  }
}

function openDeleteVideo() {
  openModalCustomConfirmation(
    {
      action: CRUD.Delete,
      type: DataType.Video,
      itemName: props.item.title,
      id: props.item.video_id,
      onConfirm: deleteVideo
    },
    modalType
  )
}

function clickAction() {
  if (!props.interactive) return
  if (creatingPlaylist.value || props.selectOnly) {
    onPlaylistAction()
    return
  } else {
    details()
  }
}

function handleOrganizationNameClicked() {
  if (!props.isAuthorByHoverable) return
  emit('on-author')
}

const playing = ref(false)

async function onMouseEnter() {
  clearTimeout(startPlaybackGuardId.value)
  if (playing.value || !videoUrl.value || !videoplayer.value) {
    return
  }

  startPlaybackGuardId.value = setTimeout(() => {
    if (!window.hls) {
      window.hls = new Hls(playerOptions)
    }

    window.hls.loadSource(videoUrl.value)
    window.hls.attachMedia(videoplayer.value)

    videoInterval.value = setInterval(() => {
      if (progressbar.value && videoplayer.value) {
        const mediaDuration = isNaN(videoplayer.value.duration) || videoplayer.value.duration < 1 ? 1 : videoplayer.value.duration
        const playerPosition = isNaN(videoplayer.value.currentTime) ? 0 : videoplayer.value.currentTime
        const progressbarPosition = (playerPosition / mediaDuration) * 100

        if (progressbarPosition < progressbar.value.value) {
          progressJump.value = true
          setTimeout(() => {
            progressJump.value = false
          }, 200)
        }
        progressbar.value.value = progressbarPosition
        if (playerPosition > 0) {
          playing.value = true
          getVideoTime()
        }
      }
    }, 300)

    playing.value = false
    showPlayer.value = true
  }, MILLISECONDS_PER_SECOND)
}

function onMouseOut() {
  clearTimeout(startPlaybackGuardId.value)
  playing.value = false
  showPlayer.value = false
  if (videoplayer.value) {
    videoplayer.value.pause
  }
  if (progressbar.value) {
    progressbar.value.value = 0
  }

  clearInterval(videoInterval.value)

  playingtime.value = formatDuration(props.item.duration_in_ms)
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.video-player {
  z-index: $zIndex1;
  opacity: 1;
  transition: opacity 0.3s ease;
  width: 100%;
  max-height: 100% !important;
  max-width: 100% !important;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:hover {
    opacity: 1;
  }
}

progress {
  background-color: #eee;
}

progress[value] {
  appearance: none;
  /* Needed for Safari */
  border: none;
  /* Needed for Firefox */
  color: $secondary-color;
  /* Fallback to a solid color */
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 4px;
  z-index: $zIndex2;
}

/* WebKit styles */
progress[value]::-webkit-progress-value {
  background-color: $secondary-color;
  transition: width 0.3s linear;
}

/* Firefox styles */
progress::-webkit-progress-bar {
  background-color: #dadbe8 !important;
}

progress::-moz-progress-bar {
  background-color: #dadbe8 !important;
}

.jump {
  progress[value]::-webkit-progress-value {
    background-color: $secondary-color;
    transition: width 0s linear;
  }
}

.video {
  width: auto;
  height: 100%;
  position: relative;
  // display: inline-block;
  border: 2px solid transparent;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .loading {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    transition-delay: 0s;

    &.visible {
      transition-delay: 0.4s;
      opacity: 1;
    }
  }

  .thumbnail {
    z-index: $zIndex0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    object-fit: contain;
    box-shadow: 10.2043px 14.2861px 29.5926px -12.2452px rgba(20, 13, 63, 0.11);
    // filter: drop-shadow(10.269px 4.08173px 85.7164px rgba(244, 244, 251, 0.2));
    background: #ffffff;
    position: absolute;
    transition: opacity 1s ease;

    &._selected {
      transition: opacity 0.5s ease;
      opacity: 0.4;
      background: rgba(20, 13, 63, 0.11);
      pointer-events: none;
    }

    &.invisible {
      opacity: 0;
      pointer-events: none;
    }
  }

  .bottom-extra-video-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: absolute;
    bottom: 0px;
    z-index: $zIndex2;
    width: 100%;
    padding: 14px 18px;
    opacity: 0;
    transition: opacity 0.3s;
    transform: translateY(100%);
    border-radius: 0 0 6px 6px;
    pointer-events: none;
    .video-extra-details {
      display: flex;
      align-items: center;
      width: 90%;
      gap: 1rem;
      .video-extra-details-title-author-container {
        width: 75%;
        .tooltip-title-text {
          width: 100%;
        }
        .title-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          width: 100%;
          margin-bottom: 5px;
        }
      }
      .author-container {
        width: 100%;
      }
      .author-by {
        display: flex;
      }
      .profile-pic {
        flex: 0 0 auto;
      }
    }
  }

  .video-duration-container {
    position: absolute;
    color: #ffffff;
    background-color: #000000;
    padding: 3px 11px;
    bottom: 10px;
    right: 14px;
    font-size: 10px;
    font-weight: 700;
    border-radius: 2px;
    transition: all 1s ease;

    &.playing {
      width: fit-content;
      left: 14px;
      opacity: 0;
    }

    &.xsmall {
      display: none;
    }
  }

  .author-container {
    display: flex;
    font-size: 10px;
    gap: 0.25rem;
    width: 75%;

    .hoverable:hover {
      text-decoration-line: underline;
    }
  }
  .bullet-separator {
    font-family: 'Arial', 'Helvetica', 'Segoe UI Symbol', 'DejaVu Sans', sans-serif;
  }
  .duration {
    font-weight: 300;
    letter-spacing: 0.1px;
    &.small {
      display: none;
    }
  }

  .video-info-container {
    pointer-events: none;
    z-index: $zIndex2;
    position: absolute;
    bottom: 1rem;
    padding-inline: 1rem;
    width: 100%;
    .title-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.2px;
      text-transform: none !important;
      margin-bottom: 0.25rem;
    }
  }

  &:not(._no-hover):hover {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.55);
    border: none;
    transition: transform 0.15s linear;
    transform: scale(1.1);
    //border-radius: 66px;
    overflow: visible;
    border-radius: 6px 6px 0 0;

    @media (max-width: $xl) {
      transform: scale(1.1);
    }

    @media (max-width: $lg) {
      transform: scale(1.1);
    }

    // TODO: temporary solution before mobile design
    @media (max-width: $md) {
      transform: scale(1.05);
    }

    &.hoverover {
      z-index: $zIndex1000;

      .playing {
        opacity: 1;
      }
    }

    .watch {
      opacity: 1;
    }

    .bottom-extra-video-info {
      opacity: 1;
      pointer-events: auto;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.45);
      .details {
        display: flex;
        justify-content: center;
      }

      img {
        &:hover {
          filter: $truvid-filter-effect !important;
        }
      }
    }

    .video-info-container {
      z-index: $zIndex2;
      opacity: 0;
      position: absolute;
    }

    &.hover-disabled {
      transform: scale(1.04);
      border-radius: 6px;
    }
  }

  .add-to-playlist-btn {
    z-index: $zIndex2;
    position: absolute;
    right: 18px;
    top: 12px;
    display: flex;
    gap: 8px;
    flex-direction: column-reverse;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .title {
    text-align: left;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    /* or 121% */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    &.top {
      color: #ffffff;
      font-size: 14px;
    }

    &.xsmall {
      display: none;
    }
  }

  .author {
    text-align: left;
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.1px;

    &.top {
      color: #ffffff;
      font-size: 10px;
    }
    .author-span {
      text-transform: capitalize;
    }
    &.xsmall {
      display: none;
    }
    &.small,
    &.xsmall {
      .duration,
      .bullet-separator {
        display: none;
      }
    }

    .bullet-point {
      margin: 0 5px;
    }
  }

  .labels {
    z-index: $zIndex2;
    top: 1rem;
    padding-inline: 1rem;
    position: absolute;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    width: calc(100% - 45px);
    letter-spacing: 0.1px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .categories {
      &.xsmall {
        display: none;
      }
    }
    &.bare {
      width: 100%;
    }
    .label {
      padding: 3px 8px;
      border-radius: 2px;
      width: fit-content;
      background: #000000;
      border-radius: 2px;
      margin-right: 5px;
      margin-bottom: 8px;
      &.small {
        display: none;
      }
    }

    /* white */
  }

  .watch {
    position: absolute;
    width: 87px;
    height: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fb2265;
    border-radius: 29.4526px;
    opacity: 0;
    margin: 0;
  }
}

.cursor {
  cursor: pointer !important;
}
</style>
