import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const playlistCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'playlist_name',
      title: 'playlists-playlist-name',
      width: 25,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 1,
      expand: true
    },
    {
      name: 'playlist_id',
      title: 'playlists-playlist-id',
      width: 15,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 2
    },
    {
      name: 'videos_amount',
      title: 'playlists-videos-numbers',
      width: 15,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 4,
      priority: 4
    },
    {
      name: 'related_tags',
      title: 'content-package-related-categories',
      width: 20,
      alignment: ListColumnAlignment.Center,
      position: 3,
      priority: 3
    },
    {
      name: 'create_date',
      title: 'create-date',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 5,
      priority: 5
    },
    {
      name: 'actions',
      title: '',
      width: 15,
      alignment: ListColumnAlignment.Center,
      position: 6,
      priority: 0
    }
  ]
})

export default playlistCols
