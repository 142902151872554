import { CRUD, DataType } from '@/interfaces'
import { RouteRecordRaw } from 'vue-router'

const libraryRoutes: RouteRecordRaw[] = [
  {
    path: 'createplaylist/:id?',
    name: 'createplaylist',
    component: () => import('@/components/modals/createplaylist/CreatePlaylist.vue'),
    meta: {
      title: 'library'
    },
    children: [
      {
        path: 'closeunsaved',
        name: 'create-closeUnsavedPlaylist',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'playlists'
        },
        props: { type: DataType.Playlist, action: CRUD.ConfirmClose }
      }
    ]
  },
  {
    path: 'embed/:id',
    name: 'library-embed',
    component: () => import('@/components/content/library/VideoEmbed.vue'),
    meta: {
      title: 'library'
    }
  },
  {
    path: 'details/:id',
    name: 'library-details',
    component: () => import('@/components/content/library/details/VideoDetails.vue'),
    meta: {
      title: 'library'
    },
    children: [
      {
        path: 'share',
        name: 'library-share',
        component: () => import('@/components/content/library/VideoShare.vue'),
        meta: {
          title: 'library'
        }
      }
    ]
  },
  {
    path: ':handle',
    name: 'library-channel',
    component: () => import('@/components/content/library/channel/LibraryChannel.vue'),
    meta: {
      title: 'library'
    },
    children: [
      {
        path: 'createplaylist',
        name: 'createplaylist-library-channel',
        component: () => import('@/components/modals/createplaylist/CreatePlaylist.vue')
      },
      {
        path: 'details/:id',
        name: 'library-channel-details',
        component: () => import('@/components/content/library/details/VideoDetails.vue')
      }
    ]
  }
]

export default libraryRoutes
