import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const widgetCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'widget_name',
      title: 'widgets-widget-name',
      width: 25,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 1,
      expand: true
    },
    {
      name: 'widget_id',
      title: 'widgets-widget-id',
      width: 12,
      minWidthInPx: '120px',
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 2
    },
    {
      name: 'widget_type',
      title: 'widgets-widget-type',
      width: 14,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 3,
      priority: 3
    },
    {
      name: 'widgets-device-type',
      title: 'widgets-device-type',
      width: 15,
      alignment: ListColumnAlignment.Center,
      position: 4,
      priority: 5
    },
    {
      name: 'create_date',
      title: 'create-date',
      width: 13,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 6,
      priority: 6
    },
    {
      name: 'status',
      title: 'status',
      width: 10,
      alignment: ListColumnAlignment.Center,
      position: 5,
      sortable: true,
      priority: 4
    },
    {
      name: 'actions',
      title: '',
      width: 15,
      alignment: ListColumnAlignment.Center,
      position: 7,
      priority: 0
    }
  ]
})

export default widgetCols
