<template>
  <div>
    <div class="ci-container number">
      <div class="input-container" :class="{ error: !valid, ...getClassList() }" :for="customId">
        <input
          :id="customId"
          v-model="inputValueLocal"
          :disabled="disabled"
          type="number"
          :step="step"
          :min="min"
          :max="max"
          :placeholder="placeholder"
          @input="handleEvents"
          @blur="validate"
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { uid } from 'uid'

const customId = ref(uid())
const inputValueLocal = ref(null)
const props = defineProps<{
  placeholder?: string
  label?: string
  valid?: boolean
  modelValue: string | number | boolean | object | null
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  classList?: string[] | null
}>()

onMounted(() => {
  inputValueLocal.value = props.modelValue
})

watch(
  () => props.modelValue,
  () => {
    inputValueLocal.value = props.modelValue
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'iconActionEmit', value: string): void
  (e: 'validate')
}>()

function handleEvents() {
  emit('update:modelValue', inputValueLocal.value)
}

function validate() {
  emit('validate')
}

function getClassList(): { [key: string]: boolean } {
  const classes = { disabled: props.disabled }
  if (!props.classList) return classes
  return { ...classes, ...Object.fromEntries(Object.entries(props.classList).map(([, v]) => [v, true])) }
}
</script>

<style scoped lang="scss">
.ci-container {
  position: relative;
}
</style>
