<script setup lang="ts">
import { ListColumnVue } from './'
import { ListColumn, ListColumnAlignment, SortOrder } from '@/interfaces'
import { onMounted, onBeforeUnmount, computed, ref } from 'vue'
import { columnsGridTemplate, breakpointDataViewType } from '@/services/layoutUtils'
import { MIN_LAYOUT_BREAKPOINTS } from '@/services/constants'
import { DataViewBreakpointValue, ViewBreakPoints } from '@/interfaces'

const props = defineProps<{
  columns: ListColumn[]
  sortBy?: string
  sortOrder?: SortOrder
}>()
const emit = defineEmits(['sort'])
const viewportWidth = ref(window.innerWidth)

function handleSort(column: ListColumn) {
  emit('sort', {
    sortBy: column.name,
    sortOrder: props.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending
  })
}

onMounted(() => {
  window.addEventListener('resize', updateViewportWidth)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateViewportWidth)
})

const updateViewportWidth = () => {
  viewportWidth.value = window.innerWidth
}

const columnsToDisplay = computed(() => {
  return (columns: ListColumn[]) => {
    if (!columns) return []

    const breakpoints = breakpointDataViewType() as unknown as DataViewBreakpointValue
    const currentBreakpoint: keyof DataViewBreakpointValue = getCurrentBreakpoint() as keyof DataViewBreakpointValue
    const maxColumns = Math.min(breakpoints[currentBreakpoint] ?? columns.length, columns.length)

    const visibleColumns = columns.filter((col) => col && col.position !== undefined).sort((a, b) => a.position - b.position)

    const priorityOneColumns = visibleColumns.filter((col) => col.priority === 1)
    const priorityZeroColumns = visibleColumns.filter((col) => col.priority === 0)

    const adjustedColumns = [...priorityOneColumns, ...priorityZeroColumns]

    if (!MIN_LAYOUT_BREAKPOINTS.includes(currentBreakpoint)) {
      const remainingColumns = visibleColumns.filter((col) => col.priority > 1)
      for (let priority = 2; priority <= Math.max(...visibleColumns.map((col) => col.priority || 0)); priority++) {
        const priorityCols = remainingColumns.filter((col) => col.priority === priority)
        if (adjustedColumns.length + priorityCols.length <= maxColumns) {
          adjustedColumns.push(...priorityCols)
        }
      }
    }
    return adjustedColumns.sort((a, b) => a.position - b.position)
  }
})

const getColumnPadding = (alignment: ListColumnAlignment) => {
  switch (alignment) {
    case 'left':
      return '1rem 1rem 1rem 0rem'
    case 'center':
      return '1rem'
    case 'right':
      return '1rem 0rem 1rem 4rem'
    default:
      return '1rem'
  }
}
const getCurrentBreakpoint = () => {
  if (viewportWidth.value >= 1900) {
    return ViewBreakPoints.hd
  } else if (viewportWidth.value >= 1600) {
    return ViewBreakPoints.xxxl
  } else if (viewportWidth.value >= 1400) {
    return ViewBreakPoints.xxl
  } else if (viewportWidth.value >= 1200) {
    return ViewBreakPoints.xl
  } else if (viewportWidth.value >= 992) {
    return ViewBreakPoints.lg
  } else if (viewportWidth.value >= 768) {
    return ViewBreakPoints.md
  } else if (viewportWidth.value >= 576) {
    return ViewBreakPoints.sm
  } else {
    return ViewBreakPoints.xs
  }
}
</script>

<template>
  <div class="columns-names" :style="`grid-template-columns: ${columnsGridTemplate(columnsToDisplay(props.columns))};`">
    <ListColumnVue
      v-for="column in columnsToDisplay(props.columns)"
      :key="column?.name"
      :column="column"
      :tooltip="column?.tooltip"
      :sort-by="props.sortBy"
      :sort-order="props.sortOrder"
      :style="{ padding: getColumnPadding(column?.alignment) }"
      @sort="() => handleSort(column)"
    />
  </div>
</template>

<style scoped lang="scss">
.columns-names {
  display: grid;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #4c4c66;
  margin-bottom: 0;
  border-bottom: 1px solid var(--title-3, #dadbe8);
  height: min-content;

  div {
    margin: 0 !important;
    padding: 1rem;
  }

  @media (max-width: $xxl) {
    .hidden-column {
      display: none;
    }
  }
}
</style>
