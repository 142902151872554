// todo: remove
export function onCloseConfirm(confirm, sideContainerRef) {
  if (confirm) {
    sideContainerRef.deactivate(true)
  } else sideContainerRef.setModalOnFocus()
}

export function setSideContainerOnFocus() {
  const sideContainerElement = document.querySelector('[id^="side-container-"]') as HTMLElement
  sideContainerElement?.focus()
}

export function isSideContainerVisible(): boolean {
  const sideContainerElement = document.querySelector('[id^="side-container-"]') as HTMLElement
  return !!sideContainerElement
}
