<template>
  <div ref="dataViewRow" class="accounts" :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`" @click.stop="openEditAccount()">
    <div class="name">
      <Tooltip :placement="'top'" :content="props.item.name" auto-ellipsis :class="shouldHideOnBreakpoint(props.columns, 'name')" />
    </div>
    <div class="website">
      <Tooltip :placement="'top'" :content="props.item.website ?? EMPTY_FIELD" auto-ellipsis :class="shouldHideOnBreakpoint(props.columns, 'website')" />
    </div>
    <div class="roles" :class="shouldHideOnBreakpoint(props.columns, 'agency_account_type')">
      <div v-if="props.item.is_publisher" class="role">{{ $t('accounts-input-type-publisher') }}</div>
      <div v-if="props.item.is_content_owner" class="role">{{ $t('accounts-input-type-content') }}</div>
    </div>
    <div class="date" :class="shouldHideOnBreakpoint(props.columns, 'created_at')">{{ getDateString(props.item.created_at) }}</div>
    <div class="name" :class="shouldHideOnBreakpoint(props.columns, 'agency_fee')">{{ props.item.agency_fee }}%</div>
    <div class="accounts-actions-container" :class="shouldHideOnBreakpoint(props.columns, 'actions')">
      <ActionButtons :actions="actions" :is-visible="isRowHovered" />
      <AccountsSelectAccount :is-visible="isRowHovered" :org-id="item.organization_id" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Tooltip } from '@/components/common'
import { getDateString } from '@/services/utils'
import { useRouter } from 'vue-router'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { ref } from 'vue'
import { useElementHover } from '@vueuse/core'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import AccountsSelectAccount from './select/AccountsSelectAccount.vue'
import { EMPTY_FIELD } from '@/services/constants'

const router = useRouter()
const props = defineProps(['item', 'columns'])
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const actions = [
  {
    label: 'edit',
    handler: openEditAccount,
    translationKey: 'edit'
  }
]

function openEditAccount() {
  router.push({ name: 'edit-account', params: { id: props.item.organization_id } })
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.roles {
  display: flex;
  flex-wrap: wrap;

  .role {
    white-space: nowrap;
    padding: 2px 8px;
    overflow: hidden;
    min-width: 160px;
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    text-transform: none;
    margin: 4px 8px 4px 0;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid var(--D2D5EC, #d2d5ec);
    color: var(--Body-text, #4c4c66);
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #dadbe8;
}

.grid {
  display: grid;
}

.accounts {
  align-items: center;
  display: grid;
  & > div {
    padding-left: 16px;
  }

  .name,
  .website {
    display: grid;
    grid-template-columns: 100%;
    overflow: hidden;
  }

  &:hover {
    background: #f3f4fa;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #4c4c66;
  }
}

.actions {
  display: flex;
  justify-content: space-around;
  padding: 0 35px;

  img {
    margin: 0 15px;
  }
}

.accounts {
  height: 80px;
  align-items: center;

  &:hover {
    background: #f3f4fa;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }
}

.accounts-actions-container {
  display: flex;
  align-items: center;
}
</style>
