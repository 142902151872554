import i18n from '@/i18n'
import api from '@/services/api'
import store from '@/store'
import type { Widget } from '@/services/widget/types'
import { Widgets } from '@/services/widget/types'
const getWidgets = async (filters: {
  list_only?: boolean
  page_number?: number
  sort_by?: string
  sort_order?: string
  results_per_page?: number
  search?: string
  status?: string
}): Promise<{ widgets: Widget[]; total: number }> => {
  const a = await api.get<Widgets>('/widgets', {
    params: filters
  })
  return a.data
}
const getWidget = async (widget_id: number): Promise<Widget> => {
  try {
    const a = await api.get<Widget>('/widget', { params: { widget_id } })
    return a.data
  } catch (error) {
    return Promise.reject(error)
  }
}
const putWidget = async (widget: Widget): Promise<Widget | undefined> => {
  try {
    const a = await api.put<Widget>('/widget', widget)
    return a.data
  } catch (error) {
    await store.dispatch('messages/addMessage', { message: error, type: 'error' })
  }
}
const postWidget = async (widget: Widget, isDuplicate: boolean = false): Promise<Widget> => {
  try {
    const a = await api.post('/widget', widget)
    if (!isDuplicate) {
      await store.dispatch('messages/addMessage', { message: i18n.global.t('widget-new-creation-confirmation-msgbox-title'), type: 'success' })
    } else {
      await store.dispatch('messages/addMessage', { message: i18n.global.t('widget-duplication-message-success'), type: 'success' })
    }
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return Promise.reject(error)
  }
}
const deleteWidget = async (id: number): Promise<void> => {
  await api.delete<Widget>(`/widget/${id}`)
}

export default { getWidgets, getWidget, putWidget, postWidget, deleteWidget }
