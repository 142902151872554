<template>
  <div class="inner" :style="styleObject" :class="{ loading: store.getters['modal/getLoadingState']() }">
    <div class="auto">
      <div v-show="store.getters['modal/getLoadingState']()" class="loader">
        <LoadingSpinner />
      </div>
      <div :class="{ hideComponent: store.getters['modal/getLoadingState']() }">
        <slot name="custom-header">
          <ModalHeader v-if="!noHeader" v-bind="store.state.modal.modalState?.props" @close-action="closeAction" />
        </slot>
        <slot name="custom-component" />
        <slot name="custom-footer">
          <ModalFooter v-if="!noFooter" v-bind="store.state.modal.modalState?.props" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'
import { CustomModalProps } from '@/interfaces'
import { onMounted, reactive } from 'vue'

const store = useStore()
const props = defineProps<CustomModalProps>()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styleObject = reactive<any>({})
const emit = defineEmits(['close-action'])

onMounted(() => {
  structureStyleObject()
})

const closeAction = async () => {
  emit('close-action')
}

function structureStyleObject() {
  if (props.height) {
    styleObject.height = props.height
  }
  if (props.width) {
    styleObject.width = props.width
  }
  if (props.padding) {
    styleObject.padding = props.padding
  }
  if (props.margin) {
    styleObject.margin = props.margin
  }
  if (props.maxWidth) {
    styleObject.maxWidth = props.maxWidth
  }
  if (props.maxHeight) {
    styleObject.maxHeight = props.maxHeight
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.inner {
  background: #ffffff;
  border: 1px solid #d7e0eb;
  box-sizing: border-box;
  box-shadow: 0px 11px 34px rgba(7, 101, 171, 0.05);
  padding: 40px 64px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  position: relative;
  color: black !important;
  justify-content: center;
  align-items: center;

  & > h2 {
    font-size: 3rem;
    font-weight: bold;
  }

  &.loading {
    .auto {
      display: flex;
      justify-content: center;
      align-items: center;

      .loader {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .hideComponent {
    opacity: 0;
  }

  .modal-header {
    .close-x {
      position: absolute;
      right: 16px;
      top: 24px;
      cursor: pointer;
    }
  }
}
</style>
