<template>
  <div ref="dataViewRow">
    <div class="content" @click="openEditUser">
      <div class="user grid" :class="{ pointer: !isLoggedInUser }" :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`">
        <div class="container">
          <div class="name-container" :class="shouldHideOnBreakpoint(props.columns, 'first_name')">
            <div class="name">
              <Tooltip :placement="'top'" :content="item.user.first_name + ' ' + item.user.last_name" auto-ellipsis />
            </div>
          </div>
        </div>
        <div class="name roles" :class="shouldHideOnBreakpoint(props.columns, 'user_permissions')">
          <div v-for="role in item.roles" :key="role.name" class="role">{{ $t(role.name) }}</div>
        </div>
        <div
          class="status"
          :class="{
            ...shouldHideOnBreakpoint(props.columns, 'is_active'),
            active: item.user.is_active === true,
            pending: item.user.is_active === false
          }"
        >
          <div>
            {{ $t(item.user.is_active ? 'active' : 'inactive') }}
          </div>
          <div />
        </div>

        <div class="name" :class="shouldHideOnBreakpoint(props.columns, 'last_login')">
          {{ isValidDateString(item.user.last_login) ? timeSince(new Date(item.user.last_login)) : EMPTY_FIELD }}
        </div>

        <div class="name" :class="shouldHideOnBreakpoint(props.columns, 'created_at')">
          {{ isValidDateString(item.user.created_at) ? getDateString(item.user.created_at) : EMPTY_FIELD }}
        </div>
        <ActionButtons :actions="actions" :is-visible="isRowHovered" :class="shouldHideOnBreakpoint(props.columns, 'actions')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiService } from '@/services'
import { timeSince, isValidDateString, getDateString, throttle } from '@/services/utils'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { useElementHover } from '@vueuse/core'
import { Tooltip } from '@/components/common'
import { EMPTY_FIELD } from '@/services/constants'
const router = useRouter()
const props = defineProps(['item', 'columns'])
const store = useStore()
const isLoggedInUser = computed(() => store.state.user.user.id === props.item.user.id)
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const onReinviteDebounced = throttle(() => {
  reInviteUser()
}, 3000)
const actions = computed(() => {
  return [
    {
      label: 'edit',
      handler: openEditUser,
      translationKey: 'edit',
      isDisabled: isLoggedInUser.value
    },
    {
      label: 'reinvite',
      handler: onReinviteDebounced,
      translationKey: 'reinvite',
      isDisabled: isValidDateString(props.item.user.last_login)
    }
  ]
})

function openEditUser() {
  if (!isLoggedInUser.value) {
    router.push({
      path: '/settings/users/edit',
      name: 'pb-edit-user',
      params: { id: props.item.user.id }
    })
  }
}

function reInviteUser() {
  apiService.organizationUser.postUserReInvite({ user_id: props.item.user.id })
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.clickable {
  cursor: pointer;

  &:hover {
    filter: $truvid-filter-effect;
  }
}

.content {
  height: fit-content;
}

.top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2em;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #8a898c;
  }
}

.top-bar {
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    color: #c2c6cc;
    margin: 0 10px 0 10px;
    cursor: pointer;
    position: relative;

    .line {
      display: none;
      position: absolute;
    }

    &.selected {
      color: #4c4c66;
      font-weight: 700;

      .line {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #2c95ff;
      }
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.button-bar {
  display: grid;
  grid-template-columns: auto 213px 263px;
  align-items: center;
  margin-top: 10px;

  .search-buttons {
    display: flex;
    align-items: center;

    .filter {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      text-transform: capitalize;
      color: #9599c6;
      display: flex;
      cursor: pointer;
      width: 100px;
      margin: 0 30px 0 10px;
      position: relative;

      img {
        margin: 0 5px 0 5px;
      }

      .filterbox {
        width: 408px;
        height: 210px;
        position: absolute;
        background: #ffffff;
        border-radius: 8px;
        filter: drop-shadow(10px 12.6px 29px rgba(67, 66, 89, 0.1));
        z-index: $zIndex12;
        top: calc(100% + 5px);
      }
    }
  }

  button {
    width: 190px;
    justify-self: end;
  }

  .edit {
    background: none;
    width: 180px;
    padding: 0;
  }
}

.button-bar {
  display: grid;
  grid-template-columns: auto 213px 263px;
  align-items: center;
  margin-top: 10px;

  .search-buttons {
    display: flex;
    align-items: center;

    .filter {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      text-transform: capitalize;
      color: #9599c6;
      display: flex;
      cursor: pointer;
      width: 100px;
      margin: 0 30px 0 10px;
      position: relative;

      img {
        margin: 0 5px 0 5px;
      }

      .filterbox {
        width: 408px;
        height: 210px;
        position: absolute;
        background: #ffffff;
        border-radius: 8px;
        filter: drop-shadow(10px 12.6px 29px rgba(67, 66, 89, 0.1));
        z-index: $zIndex12;
        top: calc(100% + 5px);
      }
    }
  }

  button {
    width: 190px;
    justify-self: end;
  }

  .edit {
    background: none;
    width: 180px;
    padding: 0;
  }
}

.search-input {
  width: 350px;
  position: relative;

  input {
    padding-left: 40px;
    border-radius: 30px;
  }

  img {
    position: absolute;
    top: 9px;
    left: 12px;
    cursor: pointer;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #dadbe8;
}

.grid {
  display: grid;
  grid-template-columns: 19% 21% 12% 12% 14% auto;
}

.actions {
  display: flex;
  justify-content: center;
}

.flex {
  white-space: nowrap !important;
}

.flex-icons {
  margin-left: 2rem;
  white-space: nowrap !important;
}

.legend {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;
  text-transform: capitalize;

  /* body text */

  color: #4c4c66;
  margin-bottom: 20px;
}

.user {
  height: fit-content;
  min-height: 80px;
  align-items: center;

  &:hover {
    background: #f3f4fa;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }

  .container {
    display: flex;

    img {
      height: 44px;
      width: 44px;
      border: 1px solid rgba(218, 219, 232, 1);
      border-radius: 100%;
      padding: 1px;
    }
  }

  .name-container {
    display: grid;
    padding-left: 0.7rem;
  }

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #4c4c66;
    display: grid;
    grid-template-columns: 100%;
    overflow: hidden;
    padding-inline-end: 1rem;
  }

  .roles {
    display: flex;
    flex-wrap: wrap;

    .role {
      white-space: nowrap;
      padding: 2px 8px;
      overflow: hidden;
      min-width: 180px;
      width: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      text-transform: none;
      margin: 4px 8px 4px 0;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid var(--D2D5EC, #d2d5ec);
      color: var(--Body-text, #4c4c66);
    }
  }

  .status {
    width: 115px;
    height: 32px;

    background: #f2f2f2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.1px;
    /* dark gray */

    color: #6f6e71;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      width: 115px;
      height: 28px;

      background: rgba(66, 200, 160, 0.1);
      border-radius: 4px;
      color: #42c8a0;
    }
  }

  .trash {
    justify-self: right;
    cursor: pointer;
    margin-right: 20px;
  }

  .ads {
    width: 157px;
    height: 32px;
    border: 1px solid #9599c6;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #9599c6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      transform: translateY(2px);
    }
  }
}
</style>
