import i18n from '@/i18n'
import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const domainsCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'title',
      title: 'content-sources-title',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1,
      expand: true
    },
    // {
    //   name: 'url',
    //   title: ('content-sources-url'),
    //   width: 15,
    //   alignment: ListColumnAlignment.Left,
    //   sortable: false
    // },
    {
      name: 'created_date',
      title: 'create-date',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 9,
      priority: 9
    },
    {
      name: 'last_run',
      title: 'content-sources-last-run',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 7,
      priority: 5,
      minWidthInPx: '160px'
    },
    {
      name: 'last_run_feed_number_of_errors',
      title: 'content-sources-last-run-num-of-errors',
      width: 11,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 8,
      priority: 8,
      tooltip: i18n.global.t('content-sources-last-run-num-of-errors-info')
    },
    {
      name: 'is_public',
      title: 'content-video-exposure',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 6,
      priority: 4,
      minWidthInPx: '160px'
    },
    {
      name: 'folder',
      title: 'content-source-folder-name',
      width: 8,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 5,
      priority: 7
    },
    {
      name: 'category',
      title: 'content-soruces-category-name',
      width: 13,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 4,
      priority: 6
    },
    {
      name: 'type',
      title: 'content-sources-type',
      width: 12,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 2
    },
    {
      name: 'status',
      title: 'status',
      width: 12,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 3,
      priority: 3
    },

    {
      name: 'actions',
      title: '',
      width: 4,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 10,
      priority: 0,
      minWidthInPx: '100px'
    }
  ]
})

export default domainsCols
